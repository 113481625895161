import { extend, localize } from 'vee-validate';
import ar from 'vee-validate/dist/locale/ar.json';
import en from 'vee-validate/dist/locale/en.json';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import {
    alpha as rule_alpha, alpha_dash as rule_alpha_dash, alpha_num as rule_alpha_num, between as rule_between, confirmed as rule_confirmed, digits as rule_digits, email as rule_email, ext, integer as rule_integer, length as rule_length, min as rule_min, regex as rule_regex, required as rule_required,
} from 'vee-validate/dist/rules';

import {
    validatorCpf,
    comissionValidatePercent,
    veripagLiquidation,
    veripagUpdateExtract,
    validatorPerCentRate,
    validatorHistoryVeripagInitial,
    liquidatedMensal, animaValidation, bankValidation, validatorAfterDate, validatorEffectiveValue, taxIdValidator, validatorAgency, validatorBankAccount, validatorBeforeDate, validatorCEP, validatorCNPJ, validatorCreditCard, validatorFileUploadBank, validatorFileUploadClient, validatorPassword, validatorPositive, validatorScheme, validatorUrlValidator, validatorValue, validatorXlsx, validatorStartDate, validatorEndDate, validatePerCent, validatorAlreadyUsed, validatorMoney, validatorRentabilitySimulator, validatorInitial, validatorPercentBordero, nsuValidation, numberValidator, pdfValidator,
    phoneValidator,
} from './validators';

// eslint-disable-next-line object-curly-newline

localize('pt_BR', ptBR);

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required);

export const email = extend('email', rule_email);

export const min = extend('min', rule_min);

export const confirmed = extend('confirmed', rule_confirmed);

export const regex = extend('regex', rule_regex);

export const between = extend('between', rule_between);

export const alpha = extend('alpha', rule_alpha);

export const integer = extend('integer', rule_integer);

export const digits = extend('digits', rule_digits);

export const alphaDash = extend('alpha-dash', rule_alpha_dash);

export const alphaNum = extend('alpha-num', rule_alpha_num);

export const length = extend('length', rule_length);

export const phone = extend('phone', {
    validate: phoneValidator,
    message: 'Digite um telefone válido',
});

export const positive = extend('positive', {
    validate: validatorPositive,
    message: 'Deve ser um número positivo',
});

export const credit = extend('credit-card', {
    validate: validatorCreditCard,
    message: 'It is not valid credit card!',
});

export const password = extend('password', {
    validate: validatorPassword,
    message: 'Sua senha deve conter 8 ou mais caracteres, letras maiúsculas e minúsculas, pelo menos um número e um caracter especial',
});

export const url = extend('url', {
    validate: validatorUrlValidator,
    message: 'URL is invalid',
});

export const taxId = extend('tax_id', {
    validate: taxIdValidator,
    message: 'Digite um documento válido',
});

export const fileUploadClient = extend('fileUploadClient', {
    validate: validatorFileUploadClient,
    message: 'Formato do nome do arquivo inválido, exemplo: CLIENTE_22.08.2022.xlsx',
});

export const fileUploadBank = extend('fileUploadBank', {
    validate: validatorFileUploadBank,
    message: 'Formato do nome do arquivo inválido, exemplo: BANCARIO_22.08.2022.xlsx',
});

export const value = extend('value', {
    validate: validatorValue,
    message: 'Valor deve ser maior que R$ 0,01',
});

export const xlsx = extend('xlsx', {
    validate: validatorXlsx,
    message: 'A extensão do arquivo deve ser xlsx',
});

export const cep = extend('cep', {
    validate: validatorCEP,
    message: 'Digite um CEP válido',
});

export const scheme = extend('scheme', {
    validate: validatorScheme,
    message: 'Esquema Inválido',
});

export const perCentRate = extend('percent-rate', {
    validate: validatorPerCentRate,
    message: 'Digite um valor válido',
});

export const perCentBordero = extend('percent-bordero', {
    validate: validatorPercentBordero,
    message: 'Digite um valor válido',
});

export const cnpj = extend('cnpj', {
    validate: validatorCNPJ,
    message: 'Digite um CNPJ válido',
});

export const beforeDate = extend('before-date', {
    validate: validatorBeforeDate,
    message: 'Digite uma data antes de hoje',
});

export const historyVeripagInitial = extend('history-after-date', {
    validate: validatorHistoryVeripagInitial,
    message: 'Data deve estar com valor no máximo 10 dias após a data inicial',
});

export const historyInitial = extend('begin-after-date', {
    validate: validatorInitial,
    message: 'Digite uma data válida',
});

export const afterDate = extend('after-date', {
    validate: validatorAfterDate,
    message: 'Digite uma data depois de hoje',
});

export const agency = extend('agency', {
    validate: validatorAgency,
    message: 'Digite uma agência válida',
});

export const bankAccount = extend('bank-account', {
    validate: validatorBankAccount,
    message: 'Digite uma conta bancária válida',
});

export const effectiveValue = extend('effective-value', {
    validate: validatorEffectiveValue,
    message: 'Digite um valor maior que R$ 1,00',
});

export const startDate = extend('start-date', {
    validate: validatorStartDate,
    message: 'Digite uma data válida',
});

export const endDate = extend('end-date', {
    validate: validatorEndDate,
    message: 'Digite uma data final válida',
});

export const anima = extend('anima', {
    validate: animaValidation,
    message: 'Formatos validos: .CSV e .XLSX',
});

export const ofx = extend('ofx', {
    validate: bankValidation,
    message: 'Formatos validos: .CSV .XLSX .OFX .TXT',
});

export const liquidatedManual = extend('liquidated_manual', {
    validate: liquidatedMensal,
    message: 'Liquidação manual deve ser menos que o valor efetivo',
});

export const validateNsu = extend('nsu', {
    validate: nsuValidation,
    message: 'Digite um NSU válido',
});

export const validateValue = extend('validateValue', {
    validate: validatorPositive,
    message: 'Digite um valor válido',
});

export const percent = extend('percent', {
    validate: validatePerCent,
    message: 'Digite um número válido',
});

export const alreadyUsed = extend('already-used', {
    validate: validatorAlreadyUsed,
    message: '{_field_} já está sendo utilizado',
});

export const comissionPercent = extend('comission-percent', {
    validate: comissionValidatePercent,
    message: 'Digite um número válido',
});

export const veripagLiquidationValidation = extend('veripag-liquidation', {
    validate: veripagLiquidation,
    message: 'O nome do arquivo está incorreto. Ele deve seguir esse exemplo: veripag_report_dd-MM-yyyy.xlsx',
});

export const veripagExtractValidation = extend('veripag-extract', {
    validate: veripagUpdateExtract,
    message: 'O nome do arquivo está incorreto. Ele deve seguir esse exemplo: veripag_relatorio_dd-MM-yyyy.xlsx',
});

export const cpfValidation = extend('cpf', {
    validate: validatorCpf,
    message: 'Digite um CPF válido',
});

export const moneyValidation = extend('money', {
    validate: validatorMoney,
    message: 'Digite um valor válido',
});

export const rentabilitySimulatorValidation = extend('rentability-simulator', {
    validate: validatorRentabilitySimulator,
    message: 'Formatos validos: .XLSX .XLSM',
});

export const numberValidation = extend('number', {
    validate: numberValidator,
    message: 'Digite um valor válido',
});

export const pdfValidation = extend('pdf', {
    validate: pdfValidator,
    message: 'O campo deve ser um PDF',
});

// Install English and Arabic localizations.
localize({
    en: {
        messages: en.messages,
        names: {
            email: 'Email',
            password: 'Password',
        },
        fields: {
            password: {
                min: '{_field_} is too short, you want to get hacked?',
            },
        },
    },
    ar: {
        messages: ar.messages,
        names: {
            email: 'البريد الإلكتروني',
            password: 'كلمة السر',
        },
        fields: {
            password: {
                min: 'كلمة السر قصيرة جداً سيتم اختراقك',
            },
        },
    },
});
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
