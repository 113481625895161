const schemes = [
  'ACC',
  'AUC',
  'BCC',
  'BNC',
  'BRS',
  'CBD',
  'CSC',
  'CZC',
  'DBC',
  'ECC',
  'FRC',
  'HCC',
  'JCC',
  'MCC',
  'MXC',
  'RCC',
  'SCD',
  'SIC',
  'TKC',
  'VCD',
  'AGC',
  'AVC',
  'BCD',
  'BRC',
  'CBC',
  'CCD',
  'CUP',
  'DAC',
  'DCC',
  'ECD',
  'GCC',
  'HCD',
  'MAC',
  'MCD',
  'OCD',
  'SCC',
  'SFC',
  'SPC',
  'VCC',
  'VDC',
]

export default schemes
