import { cnpj, cpf } from 'cpf-cnpj-validator';
import allSchemes from '@/@core/utils/scheme';

export const validatorPositive = value => {
    if (value >= 0) {
        return true;
    }
    return false;
};

export const validatorPassword = password => {
    const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[(){}\[\]\+\-\=_.,:;?!@#\$%\^&\*]).{8,}$/;
    const validPassword = regExp.test(password);
    return validPassword;
};

export const validatorCreditCard = creditnum => {
    /* eslint-disable no-useless-escape */
    const cRegExp = /^(?:3[47][0-9]{13})$/;
    /* eslint-enable no-useless-escape */
    const validCreditCard = cRegExp.test(creditnum);
    return validCreditCard;
};

export const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
        return true;
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    /* eslint-enable no-useless-escape */
    return re.test(val);
};

export const taxIdValidator = tax => {
    if (cpf.isValid(tax)) return cpf.isValid(tax);
    if (cnpj.isValid(tax)) return cnpj.isValid(tax);
    return false;
};

export const validatorFileUploadClient = file => {
    const { name } = file;

    const [fileName, date] = name.split('_');
    const [day, month, year, extension] = date.split('.');

    if (!fileName || !day || !month || !year || !extension) {
        return false;
    }

    try {
        if (fileName === 'CLIENTE') {
            if (+day > 0 && +(+day) <= 31) { if (+month > 0 && +month <= 12) if (+year >= 2022 && +year <= 2100) if (extension === 'xlsx') return true; }
        }
    } catch {
        return false;
    }

    return false;
};

export const validatorFileUploadBank = file => {
    const { name } = file;

    const [fileName, date] = name.split('_');
    const [day, month, year, extension] = date.split('.');

    if (!fileName || !day || !month || !year || !extension) {
        return false;
    }

    try {
        if (fileName === 'BANCARIO') {
            if (+day > 0 && +(+day) <= 31) { if (+month > 0 && +month <= 12) if (+year >= 2022 && +year <= 2100) if (extension === 'xlsx') return true; }
        }
    } catch {
        return false;
    }

    return false;
};

export const validatorXlsx = file => {
    const { name } = file;
    const fileNameSplitted = name.split('.');

    return fileNameSplitted[fileNameSplitted.length - 1] === 'xlsx';
};

export const validatorRentabilitySimulator = file => {
    const { name } = file;
    const fileNameSplitted = name.split('.');

    return validatorXlsx(file) || fileNameSplitted[fileNameSplitted.length - 1].toLocaleLowerCase() === 'xlsm';
};

export const validatorPerCentRate = value => value >= 0 && value <= 100;

export const validatorValue = value => !!+value.replace(/[R$.]/g, '').replace(',', '.');

export const validatorCEP = (cep, address) => {
    const city = address[0];
    const regex = /^\d{5}-\d{3}$/;
    if (!regex.test(cep)) return false;

    if (city == undefined) {
        return false;
    }
    if (city == ' undefined') {
        return false;
    }

    return true;
};

export const validatorScheme = scheme => {
    const arrayCompareted = scheme.map(s => {
        const schemeFiltered = allSchemes.filter(
            sche => sche.toUpperCase() === s.toUpperCase(),
        );
        if (schemeFiltered.length === 0) return false;
        return true;
    });

    return !arrayCompareted.includes(false);
};

export const validatorCNPJ = cnpjDocument => cnpj.isValid(cnpjDocument);

export const validatorBeforeDate = date => new Date(date) <= new Date();

export const validatorAgency = agency => agency.length === 4;

export const validatorBankAccount = account => account.length > 0 && account.length < 20 && account.includes('-');

export const validatorEffectiveValue = money => {
    const moneyWithoutSimbols = +money.replace(/[R$.]/g, '').replace(',', '.');

    return moneyWithoutSimbols >= 1;
};

export const validatorAfterDate = date => {
    const today = new Date();
    const dateToValidation = new Date(date);

    dateToValidation.setDate(dateToValidation.getDate() + 1);
    return (
        today.toDateString() === dateToValidation.toDateString()
    || today <= dateToValidation
    );
};
export const validatorStartDate = date => {};

export const validatorEndDate = (date, startDate) => date > startDate;

const validateConciliation = (name, extensions) => {
    const splitedFileName = name.split('.');
    const extension = splitedFileName[splitedFileName.length - 1];

    return extensions.includes(extension.toLocaleLowerCase());
};

export const animaValidation = ({ name }) => validateConciliation(name, ['csv', 'xlsx']);

export const bankValidation = ({ name }) => validateConciliation(name, ['csv', 'xlsx', 'ofx', 'txt']);

export const liquidatedMensal = (test, test1) => {
    const removeSymbolsMoney = value => (typeof value === 'string'
        ? +value.replace(/[R$]/g, '').replace(',', '.')
        : value);
    const data = JSON.parse(test1);
    const {
        manual_liquidated: manualLiquidated,
        paid_value: paidValue,
        effect_value: effectValue,
    } = data;
    const manualLiquidatedNoSymbols = removeSymbolsMoney(manualLiquidated);

    return (manualLiquidatedNoSymbols ?? 0) + paidValue <= effectValue;
};

export const validatePerCent = rawValue => {
    const value = +rawValue;

    if (!value && value !== 0) {
        return false;
    }

    return (value > 0 && value <= 100);
};

export const comissionValidatePercent = value => value >= 0 && value <= 100;

export const validatorAlreadyUsed = (value, values) => !values.map(item => item.toLocaleLowerCase()).includes(value.toLocaleLowerCase());

export const veripagLiquidation = value => /VERIPAG_REPORT_\d{2}-\d{2}-\d{4}-\d{2}-\d{2}\.xlsx/.test(value);
export const veripagUpdateExtract = value => /VERIPAG_RELATORIO_\d{2}-\d{2}-\d{4}-\d{2}_\d{2}\.xlsx/.test(value);

export const validatorCpf = value => cpf.isValid(value);

export const validatorMoney = value => !Number.isNaN(+value.replace(/[R$ .]/g, '').replace(',', '.'));

export const validatorHistoryVeripagInitial = (value, date) => {
    const valueInDate = new Date(value);
    const dateCompare = new Date(date);

    const timeDifference = valueInDate.getTime() - dateCompare.getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24); // in days

    return daysDifference <= 10;
};

export const validatorInitial = (value, [date]) => value > date;

export const validatorPercentBordero = value => {
    const format = v => +(v.replace(',', '.'));

    if (!value) {
        return false;
    }

    if (value.includes('.')) {
        return false;
    }

    const formattedValue = format(value);
    if (!formattedValue && formattedValue !== 0) {
        return false;
    }

    if (formattedValue < 0 || formattedValue > 100) {
        return false;
    }

    return true;
};

function isNumber(n) {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const nsuValidation = value => value.length === 9 && isNumber(value);

export const numberValidator = value => !!(+value.replace(',', ''));

export const pdfValidator = value => validateConciliation(value.name, ['pdf']);

export const phoneValidator = value => {
    // eslint-disable-next-line no-useless-escape, no-invalid-regexp
    const regex = /^\(\d{2}\) \d{4,5}-\d{4}$/;

    return regex.test(value);
};
